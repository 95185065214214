import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ClrDatagridSortOrder } from '@clr/angular';
import { combineLatest, Observable } from 'rxjs';
import { EmailsClient, File } from 'src/app/api.service';
import { AuthService } from 'src/app/auth.service';
import { FeedbackService } from 'src/app/form-layout/feedback.service';
import { DownloadService } from '../../download.service';
import { ContractAttachmentControlFactory } from './contract-attachment-control-factory';
import { SubawardsService } from '../subawards.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'subs-subaward-attachments',
  templateUrl: './subaward-attachments.component.html',
  styleUrls: ['./subaward-attachments.component.scss'],
})
export class SubawardAttachmentsComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() checklist: UntypedFormGroup;
  @Input() projectTitle: string;
  @Input() contractAttachments: UntypedFormArray;
  @Input() approved: boolean;

  @Output() contractAttachment: EventEmitter<UntypedFormGroup> =
    new EventEmitter<UntypedFormGroup>();
  @Output() idxToRemove: EventEmitter<number> = new EventEmitter<number>();
  @Output() onNotApproved: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSequenceErrorFixed: EventEmitter<void> = new EventEmitter<void>();
  @Output() financialEmailClicked = new EventEmitter<boolean>();
  @Output() resendInternalDistributionEmail: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() resendPurchaseOrderNotificationEmail: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() resendSubUnilateralModEmail: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() sendBilateralOrUnilateralEmail: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() routeForSignatureClicked = new EventEmitter();
  @Output() signAgreementClicked = new EventEmitter<number>();
  @Output() attachmentSelected: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() returnedToDA: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() generateFullAgreementPDF: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() sendSubSignatureEmail: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() sendReturnedToDAEmail: EventEmitter<void> =
    new EventEmitter<void>();

  indexToDelete = -1;
  fileNameToDelete = '';
  deleteOpened = false;
  fullAgreement = -1;
  signaturePage = -1;
  currentSigPageId = -1;
  showReturnCommentModal = false;
  ascSort = ClrDatagridSortOrder.ASC;
  showModTypeError = false;

  alertSubject$ = this.feedbackService.alerts;
  internalDistributionSent$: Observable<boolean | null>;
  unilateralModSent$: Observable<boolean | null>;
  showDistributionEmailButton$: Observable<boolean>;
  showModEmailButton$: Observable<boolean>;

  constructor(
    private controlFactory: ContractAttachmentControlFactory,
    private router: Router,
    private downloadService: DownloadService,
    private authService: AuthService,
    private emailClient: EmailsClient,
    public subawardsService: SubawardsService,
    private feedbackService: FeedbackService,
  ) {
    this.alertSubject$ = this.feedbackService.alerts;
    this.internalDistributionSent$ =
      this.subawardsService.InternalDistributionSent;
    this.unilateralModSent$ = this.subawardsService.UnilateralModSent;

    this.showDistributionEmailButton$ = combineLatest([
      this.internalDistributionSent$,
      this.subawardsService.Approved,
    ]).pipe(
      map(
        ([internalSent, approved]) =>
          this.canAccessApproveAndResend() && approved && !internalSent,
      ),
    );

    this.showModEmailButton$ = combineLatest([
      this.unilateralModSent$,
      this.subawardsService.Approved,
    ]).pipe(
      map(
        ([modSent, approved]) =>
          this.canAccessApproveAndResend() && approved && !modSent,
      ),
    );
  }

  get commentsForm() {
    return this.form.controls.comments;
  }

  get isNewSubmission(): boolean {
    const agreementNum = this.form.get(
      'washUInformation.agreementNumber',
    ).value;
    if (
      agreementNum === null ||
      agreementNum === undefined ||
      agreementNum.trim() === ''
    ) {
      return true;
    } else {
      return agreementNum.toLowerCase().includes('mod') ? false : true;
    }
  }

  get modTypeSelected() {
    return this.form.get('modType').value;
  }

  get modTypeControl() {
    return this.form.get('modType');
  }

  ngOnInit() {
    this.contractAttachments.valueChanges.subscribe((val) => {
      this.updateIndexAndSequence(val);
    });

    this.allowRouteForSignature();
    this.updateIndexAndSequence(this.contractAttachments.value);
  }

  public canAccessApproveAndResend() {
    return this.authService.hasPermission('AccessButtonApprove');
  }

  fileUploaded({ fileName, fileId }: { fileName: string; fileId: string }) {
    const control = this.controlFactory.createContractAttachmentControl({
      id: null,
      fileName,
      fileId,
      fullAgreement: false,
      source: 'User',
      signaturePage: false,
      signed: false,
      sequence: null,
      unilateral: null,
      utilitiesAttachmentTemplateId: null,
      created: new Date().toISOString(),
    });

    this.contractAttachment.emit(control);
  }

  updateIndexAndSequence(val) {
    let signatureIndex = -1;
    let fullAgreementIndex = -1;
    val.forEach((element, index) => {
      if (element.fullAgreement) {
        fullAgreementIndex = index;
        if (element.sequence !== '0.0') {
          this.contractAttachments.at(index).get('sequence').setValue('0.0');
        }
      }

      if (this.canBeSignaturePage(element)) {
        if (element.signaturePage === true) {
          signatureIndex = index;
          this.currentSigPageId = this.contractAttachments
            .at(index)
            .get('id').value;
        }
      }
    });
    this.signaturePage = signatureIndex;
    this.fullAgreement = fullAgreementIndex;
  }

  download(contractAttachment: UntypedFormGroup) {
    const index = this.contractAttachments.controls.indexOf(contractAttachment);
    const { fileId, fileName } = this.contractAttachments.value[index];
    this.downloadService
      .downloadFile(fileId, fileName)
      .subscribe((result) => {});
  }

  disableSignaturePage(contractAttachment: UntypedFormGroup) {
    const index = this.contractAttachments.controls.indexOf(contractAttachment);
    return (index !== this.signaturePage && this.signaturePage !== -1) ||
      !this.authService.hasPermission('AccessCheckBoxSignaturePage')
      ? true
      : null;
  }

  disableFullAgreement(contractAttachment: UntypedFormGroup) {
    const index = this.contractAttachments.controls.indexOf(contractAttachment);
    return (index !== this.fullAgreement && this.fullAgreement !== -1) ||
      this.contractAttachments.at(index).get('sequence').value !== '0.0' ||
      !this.authService.hasPermission('AccessCheckBoxFullAgreement')
      ? true
      : null;
  }

  allowSignAgreement() {
    return (
      this.authService.hasPermission('AccessButtonSignAgreement') &&
      this.signaturePage !== -1
    );
  }

  async approve() {
    const agreementNumber = this.form.get(
      'washUInformation.agreementNumber',
    ).value;

    if (
      agreementNumber === null ||
      agreementNumber === undefined ||
      agreementNumber === ''
    ) {
      this.feedbackService.alert(
        'Cannot complete APPROVE process. No WU Agreement Number has been entered.',
      );
      return;
    }

    if (this.isNewSubmission) {
      this.sendSubSignatureEmail.emit();
    } else {
      if (
        this.form.get('subrecipientInformation.fundNumberChanged').value ===
          null ||
        this.form.get('subrecipientInformation.scopeOfWorkChanged').value ===
          null
      ) {
        this.feedbackService.alert(
          'Cannot complete APPROVE process. Not all questions have been answered on the Subaward Details tab.<br/>\n' +
            'Check the following questions:<br/>\n' +
            '    <ul>\n' +
            '      <li >Has the scope of work changed?</li>\n' +
            '      <li>Has the Fund Number Changed from the Previous Year?\n' +
            '   </li>\n' +
            '    </ul>',
        );
        return;
      } else {
        this.sendBilateralOrUnilateralEmail.emit();
      }
    }
  }

  async sendInternalDistributionEmail(feaResend) {
    this.resendInternalDistributionEmail.emit(feaResend);
  }

  sendPurchaseOrderNotificationEmail() {
    this.resendPurchaseOrderNotificationEmail.emit();
  }

  async sendSubUnilateralModEmail() {
    this.resendSubUnilateralModEmail.emit();
  }

  deleteAttachment(contractAttachment: UntypedFormGroup) {
    const index = this.contractAttachments.controls.indexOf(contractAttachment);
    this.indexToDelete = index;
    this.fileNameToDelete = this.contractAttachments.value[index].fileName;
    this.deleteOpened = true;
  }

  cancelDeleteConfirmation() {
    this.indexToDelete = -1;
    this.deleteOpened = false;
  }

  confirmDelete() {
    this.idxToRemove.emit(this.indexToDelete);
    this.indexToDelete = -1;
    this.deleteOpened = false;
  }

  signAgreement() {
    this.signAgreementClicked.emit(this.signaturePage);
  }

  disapproveContractAttachments() {
    this.onNotApproved.emit();
  }

  canBeFullAgreement(attachment: any) {
    if (attachment.fileId) {
      const fullAgreementExtension = 'pdf';
      const fileExtension = this.getFileExtension(attachment.fileName);
      const fileSequence = attachment.sequence;

      if (
        fullAgreementExtension.includes(fileExtension) &&
        fileSequence === '0.0'
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  canBeSignaturePage(attachment: any) {
    if (attachment.fileId) {
      const signaturePageExtension = 'docx';
      const fileExtension = this.getFileExtension(attachment.fileName);
      const fileSequence = attachment.sequence;

      if (
        signaturePageExtension.includes(fileExtension) &&
        fileSequence === '0.0'
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  getFileExtension(fileName: string) {
    if (!fileName) {
      return '';
    }
    return fileName.split('.').pop();
  }

  canBeSelected(fileExt: string, docType: string) {
    if (!fileExt) {
      return true;
    }
    return docType === fileExt.split('.').pop() ? null : true;
  }

  navigateToSelectAttachments() {
    this.attachmentSelected.emit();
  }

  closeReturnCommentModal(val: boolean) {
    this.showReturnCommentModal = val;
  }

  returnToDA(val: boolean) {
    if (val) {
      this.form.get('washUInformation.status').setValue('RD');
      this.returnedToDA.emit(true);
      this.sendReturnedToDAEmail.emit();
    }
  }

  allowRouteForSignature() {
    return (
      this.form.get('washUInformation.debarment').value &&
      this.form.get('washUInformation.exportControl').value &&
      this.form.get('washUInformation.debarmentComments').value &&
      this.form.get('washUInformation.exportControlComments').value &&
      this.form.get('washUInformation.subcontractType').value &&
      this.form.get('washUInformation.farContract').value !== null &&
      this.form.get('washUInformation.ffataReportable').value !== null
    );
  }

  routeForSignature() {
    if (!this.modTypeControl.value) {
      this.showModTypeError = true;
      return;
    }
    this.routeForSignatureClicked.emit();
  }

  saveSubaward() {
    this.onSequenceErrorFixed.emit();
  }
}
