<div
  class="form-container"
  style="height: calc(100% - 38px); display: flex; flex-direction: column"
>
  <div style="flex-grow: 1; overflow-y: auto; padding-right: 24px">
    <div class="card">
      <div class="card-header">Sub Recipient Entry for {{ projectTitle }}</div>
    </div>
    <subs-checklist [checklist]="checklist"> </subs-checklist>
    <subs-fully-executed-agreement [canEdit]="false">
    </subs-fully-executed-agreement>

    <div class="card">
      <div class="card-header">Additional Documents (Optional)</div>
      <div class="card-block">
        <subs-alert [alertBehavior]="uploadButton.uploadError"> </subs-alert>
        <div class="clr-row">
          <subs-upload-button
            #uploadButton
            (uploaded)="fileUploaded($event)"
            (click)="resetErrors()"
            id="additional-document-upload"
            style="margin: 0 0 0.3rem 0.5rem"
            >Add</subs-upload-button
          >
          <div *ngIf="wordDocumentExtensionError">
            <span class="label label-danger"
              >All Word documents should be in .docx format.</span
            >
          </div>
        </div>

        <clr-datagrid data-test-id="additional-document-grid">
          <clr-dg-column
            ><ng-container> File Name </ng-container></clr-dg-column
          >
          <clr-dg-column><ng-container> Created </ng-container></clr-dg-column>
          <clr-dg-column
            ><ng-container>
              Included in Contract
              <a
                role="tooltip"
                aria-haspopup="true"
                class="tooltip tooltip-md tooltip-bottom-left"
              >
                <clr-icon shape="info-circle" size="22"></clr-icon>
                <span class="tooltip-content"
                  >Only PDF or Word documents can be included.</span
                >
              </a>
            </ng-container></clr-dg-column
          >

          <clr-dg-row
            *ngFor="
              let additionalDocument of additionalDocuments.controls;
              let i = index
            "
            [formGroup]="additionalDocument"
            ><clr-dg-action-overflow>
              <button
                class="action-item"
                (click)="download(i)"
                clrPopoverCloseButton
                data-test-id="download"
              >
                Download
              </button>
              <button
                class="action-item"
                (click)="deleteAdditionalDocument(i)"
                clrPopoverCloseButton
                data-test-id="delete"
              >
                Delete
              </button>
            </clr-dg-action-overflow>

            <clr-dg-cell>{{
              additionalDocument.controls.fileName.value
            }}</clr-dg-cell>
            <clr-dg-cell>{{
              additionalDocument.controls.dateUploaded.value | localTime
            }}</clr-dg-cell>
            <clr-dg-cell
              style="
                padding-top: 0;
                padding-bottom: 0;
                display: flex;
                align-items: center;
              "
            >
              <subs-checkbox
                *ngIf="
                  canInclude(additionalDocument.controls.fileName.value) ===
                  null
                "
                containerStyle="margin:0;
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            align-items: center; "
                controlName="included"
                labelLength="0"
                [disabled]="
                  canInclude(additionalDocument.controls.fileName.value)
                "
              >
              </subs-checkbox>
            </clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer
            >{{
              additionalDocuments ? additionalDocuments.length : 0
            }}
            documents</clr-dg-footer
          >
        </clr-datagrid>
      </div>
      <clr-modal [(clrModalOpen)]="deleteOpened">
        <h3 class="modal-title">Confirm Delete?</h3>

        <div class="modal-body">
          <p>
            Are you sure you would like to delete
            <strong>{{ fileNameToDelete }}</strong
            >?
          </p>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline"
            (click)="cancelDeleteConfirmation()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            (click)="confirmDelete()"
            data-test-id="confirm-delete"
          >
            Delete
          </button>
        </div>
      </clr-modal>
    </div>
  </div>
</div>
