import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Subaward, SubawardsClient, UserProfilesClient } from '../api.service';
import { FormGroup } from '@angular/forms';
import { FeedbackService } from '../form-layout/feedback.service';

@Injectable({
  providedIn: 'root',
})
export class SubawardsService {
  private subaward = new BehaviorSubject<Subaward | null>(null);
  private subawardStatus = new BehaviorSubject<string | null>(null);
  private newPoCoRequestId = new BehaviorSubject<boolean | null>(null);
  private approved = new BehaviorSubject<boolean | null>(null);
  private internalDistributionSent = new BehaviorSubject<boolean | null>(null);
  private unilateralModSent = new BehaviorSubject<boolean | null>(null);
  private poNotificationSent = new BehaviorSubject<boolean | null>(null);
  private feaUploaded = new BehaviorSubject<boolean | null>(null);
  private feaForm = new BehaviorSubject<FormGroup | null>(null);
  private newPoCoEncumbId = new BehaviorSubject<string | null>(null);
  private newSupplierContractId = new BehaviorSubject<string | null>(null);
  private indirectCostBaseMethod = new BehaviorSubject<string | null>(null);
  private osrsGaList = new BehaviorSubject<Array<string> | []>(null);
  private uploadedFeaFile: {
    file: File;
    fileId: string;
    fromUploadEvent: boolean;
  } | null = null;
  private feaUploadSubscription: Subscription | null = null;

  constructor(
    private subawardClient: SubawardsClient,
    private userProfileClient: UserProfilesClient,
    private feedbackService: FeedbackService,
  ) {}

  get Subaward(): Observable<Subaward | null> {
    return this.subaward.asObservable();
  }

  get SubawardStatus(): Observable<string | null> {
    return this.subawardStatus.asObservable();
  }

  get Approved(): Observable<boolean | null> {
    return this.approved.asObservable();
  }

  get InternalDistributionSent(): Observable<boolean | null> {
    return this.internalDistributionSent.asObservable();
  }

  get UnilateralModSent(): Observable<boolean | null> {
    return this.unilateralModSent.asObservable();
  }

  get PoNotificationSent(): Observable<boolean | null> {
    return this.poNotificationSent.asObservable();
  }

  get FeaUploaded(): Observable<boolean | null> {
    return this.feaUploaded.asObservable();
  }

  getUploadedFeaFile(): {
    file: File;
    fileId: string;
    fromUploadEvent: boolean;
  } | null {
    return this.uploadedFeaFile ? { ...this.uploadedFeaFile } : null;
  }

  get FeaForm(): Observable<FormGroup | null> {
    return this.feaForm.asObservable();
  }

  get PoCoRequestId(): Observable<boolean | null> {
    return this.newPoCoRequestId.asObservable();
  }

  get PoCoEncumbId(): Observable<string | null> {
    return this.newPoCoEncumbId.asObservable();
  }

  get SupplierContractId(): Observable<string | null> {
    return this.newSupplierContractId.asObservable();
  }

  get IndirectCostBaseMethod(): Observable<string | null> {
    return this.indirectCostBaseMethod.asObservable();
  }

  get OsrsGaList(): Observable<Array<string> | null> {
    return this.osrsGaList.asObservable();
  }

  selectSubaward(id: number) {
    if (this.subaward.getValue()?.id === id) {
      return;
    }
    this.subaward.next(null); // 🛑 Clear previous data

    this.subawardClient.get(id).subscribe(
      (subaward) => {
        this.subaward.next(subaward);

        this.setFeaUploaded(false, null);
      },
      (error) => {
        this.subaward.next(null);
        this.feedbackService.alert(
          'Failed to load subaward data. Please try again.',
        );
      },
      () => {
        console.log('✅ Subaward API call completed for ID:', id);
      },
    );
  }

  setsubawardStatus(status: string) {
    this.subawardStatus.next(status);
  }

  setApproved(approveClicked: boolean) {
    this.approved.next(approveClicked);
  }

  setInternalDistributionSent(emailSent: boolean) {
    this.internalDistributionSent.next(emailSent);
  }

  setUnilateralModSent(emailSent: boolean) {
    this.unilateralModSent.next(emailSent);
  }

  setPoNotificationSent(emailSent: boolean) {
    this.poNotificationSent.next(emailSent);
  }

  setFeaUploaded(
    feaUploaded: boolean,
    uploadedFile: {
      file: File;
      fileId: string;
      fromUploadEvent?: boolean;
    } | null = null,
  ) {
    if (this.uploadedFeaFile?.fileId !== uploadedFile?.fileId) {
      this.uploadedFeaFile = uploadedFile
        ? {
            ...uploadedFile,
            fromUploadEvent: uploadedFile.fromUploadEvent ?? false,
          }
        : null;
    }
    this.feaUploaded.next(feaUploaded);
  }

  setFeaForm(form: FormGroup) {
    this.feaForm.next(form);
  }

  setFeaFileData(
    fileId: string | null,
    subrecipientId: number | null,
    fileName: string | null,
    dateUploaded: string | null,
  ) {
    const updatedSubaward = this.subaward.getValue();

    if (updatedSubaward) {
      if (!updatedSubaward.fullyExecutedAgreement) {
        updatedSubaward.fullyExecutedAgreement = {};
        this.internalDistributionSent.next(false);
        this.poNotificationSent.next(false);
      } else {
        updatedSubaward.fullyExecutedAgreement.fileId = fileId;
        updatedSubaward.fullyExecutedAgreement.subrecipientId = subrecipientId;
        updatedSubaward.fullyExecutedAgreement.fileName = fileName;
        updatedSubaward.fullyExecutedAgreement.dateUploaded = dateUploaded;
        this.subaward.next(updatedSubaward);
      }

      const feaForm = this.feaForm.getValue();
      if (feaForm) {
        feaForm.patchValue({
          fileId,
          fileName,
          dateUploaded,
        });
        this.feaForm.next(feaForm);
      }
    }
  }

  setSubawardContact(contact: any) {
    const updatedSubaward = this.subaward.getValue();
    updatedSubaward.contact = contact;
    this.subaward.next(updatedSubaward);
  }

  setPoCoRequestId(poCoRequestId: boolean) {
    this.newPoCoRequestId.next(poCoRequestId);
  }

  setPoCoEncumbId(poCoEncumbId: string) {
    this.newPoCoEncumbId.next(poCoEncumbId);
  }

  setSupplierContractId(supplierContractId: string) {
    this.newSupplierContractId.next(supplierContractId);
  }

  setIndirectCostBaseMethod(indirectCostBaseMethod: string) {
    this.indirectCostBaseMethod.next(indirectCostBaseMethod);
  }

  setOsrsGaList() {
    this.userProfileClient
      .getUsersByPermission('OsrsGaUser')
      .subscribe((val) => {
        const gaList = [];
        val.forEach((u) => {
          gaList.push(u.employeeName);
        });
        this.osrsGaList.next(gaList);
      });
  }

  resetState() {
    this.subaward.next(null);
    this.internalDistributionSent.next(false);
    this.poNotificationSent.next(false);
    this.feaUploaded.next(false);
    this.uploadedFeaFile = null;
  }
}
