<div class="form-container">
  <form
    clrForm
    [formGroup]="form"
    style="flex-grow: 1; overflow-y: auto; padding-right: 24px"
  >
    <div class="card">
      <div class="card-header">Sub Recipient Entry for {{ projectTitle }}</div>
      <div class="card-block">
        <button class="btn btn-ouline">Generate Subagreement</button>
        <button
          class="btn btn-ouline"
          data-test-id="show-return-comments-modal"
          (click)="showReturnCommentModal = true"
        >
          Return to DA
        </button>
      </div>
    </div>
    <subs-checklist [checklist]="checklist"> </subs-checklist>

    <subs-washu-information
      data-test-id="washU-information"
      [form]="form.controls.washUInformation"
      [showSubawardId]="true"
      [showComplianceChecks]="true"
      [showGenerateAgreementNumber]="true"
    ></subs-washu-information>

    <subs-collaborator
      [displayAllComponents]="false"
      data-test-id="subrecipient-information"
      [form]="form.controls.subrecipientInformation"
      (financialEmailClicked)="this.financialEmailClicked.emit(true)"
    ></subs-collaborator>

    <div class="card">
      <div class="card-header">Contract Attachments</div>
      <div class="card-block">
        <subs-alert [alertBehavior]="uploadButton.uploadError"> </subs-alert>
        <div class="clr-row">
          <subs-upload-button
            #uploadButton
            (uploaded)="fileUploaded($event)"
            [acceptedMimeTypes]="[
              'application/pdf',
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            ]"
            acceptedMimeTypesErrorMessage="Can only upload PDF and Word documents."
            id="contract-attachment-document-upload"
            style="margin: 0 0 0.3rem 0.5rem"
            >Add</subs-upload-button
          >
          <subs-subaward-modtype-select
            class="no-top-margin modtype-select"
            controlName="modType"
            label="Unilateral/Bilateral *"
            (change)="showModTypeError = false"
          >
          </subs-subaward-modtype-select>
          <span *ngIf="showModTypeError" style="margin-left: 12px">
            <clr-icon
              class="modtype-icon"
              shape="exclamation-circle"
              size="22"
            ></clr-icon>
            <clr-stack-label class="modtype-error"
              >A Unilateral/Bilateral selection is required.</clr-stack-label
            >
          </span>
        </div>

        <clr-datagrid data-test-id="contract-attachments-document-grid">
          <clr-dg-column
            [style.width.px]="135"
            clrDgField="controls.sequence.value"
            [clrDgSortOrder]="ascSort"
          >
            Sequence
            <a
              role="tooltip"
              aria-haspopup="true"
              class="tooltip tooltip-md tooltip-bottom-right"
            >
              <clr-icon shape="info-circle" size="22"></clr-icon>
              <span class="tooltip-content">
                Format: xx.x. Example: 0.0, 99.1
              </span>
            </a>
          </clr-dg-column>
          <clr-dg-column
            [style.width.px]="135"
            clrDgField="controls.fullAgreement.value"
            ><ng-container>
              Full Agreement
              <a
                role="tooltip"
                aria-haspopup="true"
                class="tooltip tooltip-md tooltip-bottom-left"
              >
                <clr-icon shape="info-circle" size="22"></clr-icon>
                <span class="tooltip-content"
                  >Only PDF documents can be selected.</span
                >
              </a>
            </ng-container></clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="115"
            clrDgField="controls.signaturePage.value"
            ><ng-container>
              Signature Page
              <a
                role="tooltip"
                aria-haspopup="true"
                class="tooltip tooltip-md tooltip-bottom-left"
              >
                <clr-icon shape="info-circle" size="22"></clr-icon>
                <span class="tooltip-content"
                  >Only Word documents can be selected.</span
                >
              </a>
            </ng-container></clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            clrDgField="controls.source.value"
            ><ng-container> File Source </ng-container></clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            clrDgField="controls.created.value"
            ><ng-container> Created </ng-container></clr-dg-column
          >
          <clr-dg-column clrDgField="controls.fileName.value"
            ><ng-container> File Name </ng-container></clr-dg-column
          >
          <clr-dg-row
            *clrDgItems="let contractAttachment of contractAttachments.controls"
            [formGroup]="contractAttachment"
            ><clr-dg-action-overflow>
              <button
                class="action-item"
                (click)="download(contractAttachment)"
                clrPopoverCloseButton
                data-test-id="download"
              >
                Download
              </button>
              <button
                class="action-item"
                (click)="deleteAttachment(contractAttachment)"
                clrPopoverCloseButton
                data-test-id="delete"
              >
                Delete
              </button>
            </clr-dg-action-overflow>
            <clr-dg-cell
              class="seq-input-cell"
              style="
                padding-top: 0;
                padding-bottom: 0;
                display: flex;
                align-items: center;
              "
              ><subs-input
                class="clr-col-7 no-top-margin"
                controlName="sequence"
                labelLength="0"
                pipe="number : 2"
                errorMessage="Required"
                (change)="saveSubaward()"
              ></subs-input
            ></clr-dg-cell>
            <clr-dg-cell
              style="
                padding-top: 0;
                padding-bottom: 0;
                display: flex;
                align-items: center;
              "
            >
              <subs-checkbox
                *ngIf="canBeFullAgreement(contractAttachment.value)"
                containerStyle="margin:0;
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            align-items: center; "
                controlName="fullAgreement"
                labelLength="0"
                [disabled]="disableFullAgreement(contractAttachment)"
              >
              </subs-checkbox>
            </clr-dg-cell>
            <clr-dg-cell
              style="
                padding-top: 0;
                padding-bottom: 0;
                display: flex;
                align-items: center;
              "
            >
              <subs-checkbox
                *ngIf="canBeSignaturePage(contractAttachment.value)"
                containerStyle="margin:0;
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            align-items: center; "
                controlName="signaturePage"
                labelLength="0"
                [disabled]="disableSignaturePage(contractAttachment)"
              >
              </subs-checkbox>
            </clr-dg-cell>
            <clr-dg-cell>{{
              contractAttachment.controls.source.value
            }}</clr-dg-cell>
            <clr-dg-cell>{{
              contractAttachment.controls.created.value | localTime
            }}</clr-dg-cell>

            <clr-dg-cell>{{
              contractAttachment.controls.fileName.value
            }}</clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer
            >{{
              contractAttachments ? contractAttachments.length : 0
            }}
            documents</clr-dg-footer
          >
        </clr-datagrid>
        <br />

        <button
          class="btn btn-outline btn-sm"
          (click)="navigateToSelectAttachments()"
          data-test-id="select-attachments"
        >
          Select Attachments
        </button>

        <button
          class="btn btn-outline btn-sm"
          data-test-id="routesignature-btn"
          (click)="routeForSignature()"
          [disabled]="allowRouteForSignature() ? null : 'disabled'"
        >
          Route for Signature
        </button>
        <span class="row">
          <button
            *subsPermission="['AccessButtonNotApproved']"
            class="btn btn-ouline btn-sm"
            (click)="disapproveContractAttachments()"
          >
            Not Approved
          </button>

          <button
            *subsPermission="['AccessButtonSignAgreement']"
            class="btn btn-outline btn-sm"
            (click)="signAgreement()"
            [disabled]="allowSignAgreement() ? null : 'disabled'"
          >
            Sign Agreement
          </button>

          <button
            *subsPermission="['AccessButtonGenerateFullAgreementPdf']"
            class="btn btn-outline btn-sm"
            (click)="generateFullAgreementPDF.emit()"
          >
            Generate Full Agreement PDF
          </button>

          <button
            *subsPermission="['AccessButtonApprove']"
            class="btn btn-outline btn-sm"
            [disabled]="!modTypeSelected"
            (click)="approve()"
          >
            Approve
          </button>
          <button
            class="btn btn-outline btn-sm"
            *ngIf="showDistributionEmailButton$ | async"
            (click)="sendInternalDistributionEmail(false)"
          >
            Send Internal Distribution Email
          </button>

          <button
            class="btn btn-outline btn-sm"
            *ngIf="showModEmailButton$ | async"
            (click)="sendSubUnilateralModEmail()"
          >
            Send Unilateral Mod Email
          </button>
        </span>
      </div>
      <clr-modal [(clrModalOpen)]="deleteOpened">
        <h3 class="modal-title">Confirm Delete?</h3>

        <div class="modal-body">
          <p>
            Are you sure you would like to delete
            <strong>{{ fileNameToDelete }}</strong
            >?
          </p>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline"
            (click)="cancelDeleteConfirmation()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            (click)="confirmDelete()"
            data-test-id="confirm-delete"
          >
            Delete
          </button>
        </div>
      </clr-modal>
    </div>
    <subs-fully-executed-agreement
      [canEdit]="true"
      (resendInternalDistributionEmail)="sendInternalDistributionEmail($event)"
      (resendExternalPONotificationEmail)="sendPurchaseOrderNotificationEmail()"
    >
    </subs-fully-executed-agreement>
  </form>
  <subs-return-comment
    data-test-id="return-comments-modal"
    [commentsFormControl]="commentsForm"
    (returnCommentModalClose)="closeReturnCommentModal($event)"
    (returnToDA)="returnToDA($event)"
    [isOpen]="showReturnCommentModal"
  >
  </subs-return-comment>
</div>
