import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FeedbackService } from '../../form-layout/feedback.service';
import {
  InstitutionAuditReportClient,
  InstitutionAuditReportResponse,
} from '../../api.service';
import { ExcelExportService } from '../excel-export.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'subs-institution-audit-report',
  templateUrl: './institution-audit-report.component.html',
  providers: [FeedbackService, DatePipe],
})
export class InstitutionAuditReportComponent {
  submitState = this.feedbackService.submitState;
  alertSubject = this.feedbackService.alerts;

  searchForm = this.fb.group({
    dateFrom: ['', Validators.required],
    dateThru: '',
    institutionName: '',
    dunsNumber: '',
    uniqueEntityId: '',
    updatedBy: '',
    institutionsCreated: [true],
    institutionsCompleted: [true],
    institutionAudit: [true],
    institutionFcoi: [true],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private datePipe: DatePipe,
    private feedbackService: FeedbackService,
    private institutionAuditReportClient: InstitutionAuditReportClient,
    private excelExportService: ExcelExportService,
  ) {}

  search() {
    this.feedbackService.beginLoading();

    if (!this.hasOptionSelected()) {
      this.feedbackService.alert(
        'Report Option is required. Please select at least one Report Option to run report.',
      );
      return;
    }

    if (this.searchForm.invalid) {
      this.feedbackService.alert(
        'The form is invalid. Please correct all errors before submitting.',
      );
    } else {
      if (!this.searchForm.controls.dateThru.value) {
        const endDate = new Date();
        this.searchForm.controls.dateThru.patchValue(
          this.datePipe.transform(endDate, 'MM/dd/yyyy'),
        );
      }
      this.institutionAuditReportClient
        .get(
          new Date(this.searchForm.controls.dateFrom.value),
          new Date(this.searchForm.controls.dateThru.value),
          this.searchForm.controls.institutionName.value,
          this.searchForm.controls.dunsNumber.value,
          this.searchForm.controls.uniqueEntityId.value,
          this.searchForm.controls.updatedBy.value,
        )
        .pipe(this.feedbackService.provideFeedback())
        .subscribe(async (val) => {
          await this.generateReport(val);
        });
    }
  }

  private hasOptionSelected() {
    return (
      this.searchForm.controls.institutionsCreated.value ||
      this.searchForm.controls.institutionsCompleted.value ||
      this.searchForm.controls.institutionAudit.value ||
      this.searchForm.controls.institutionFcoi.value
    );
  }

  async generateReport(results: InstitutionAuditReportResponse) {
    if (
      results.completedInstitutions.length === 0 &&
      results.newInstitutions.length === 0 &&
      results.institutionAuditLogs.length === 0 &&
      results.subInstitutionFCOIPolicies.length === 0
    ) {
      this.feedbackService.alert('There are no results for your search');
      return;
    }

    const headers = this.buildHeaders(results);
    await this.excelExportService.generateExcel(
      'Institutions Audit Report',
      `Institutions Audit Report for the period ${this.searchForm.controls.dateFrom.value} - ${this.searchForm.controls.dateThru.value}`,
      this.generateReportData(results, headers),
      this.getAllColumns(headers),
      {
        value: `Date Range: ${this.searchForm.controls.dateFrom.value} - ${this.searchForm.controls.dateThru.value},
            Institution Name: ${this.searchForm.controls.institutionName.value},
            DUNS: ${this.searchForm.controls.dunsNumber.value},
            UEI: ${this.searchForm.controls.uniqueEntityId.value},
            Updated By: ${this.searchForm.controls.updatedBy.value}`,
      },
      this.addHeadersAndStyles.bind(this),
      headers,
    );
  }

  private buildHeaders(results: InstitutionAuditReportResponse) {
    const columns = [
      { header: 'Institution ID', width: 20 },
      { header: 'DUNS', width: 20 },
      { header: 'UEI', width: 20 },
      { header: 'Institution Name', width: 40 },
      { header: 'Risk Category', width: 20 },
      { header: 'Supplier ID', width: 20 },
      { header: 'Foreign Institution', width: 20 },
      { header: 'Created By', width: 25 },
      { header: 'Created Date', width: 15 },
      { header: 'Institution Status', width: 20 },
    ];
    const headers = [];

    if (this.searchForm.controls.institutionsCreated.value) {
      headers.push({
        title: 'New Institutions Created',
        sort: 1,
        count: results.newInstitutions.length,
        columns: columns.map((c) => ({ value: c.header })),
      });
    }

    if (this.searchForm.controls.institutionsCompleted.value) {
      headers.push({
        title: 'New Institutions Completed',
        sort: 2,
        count: results.completedInstitutions.length,
        columns: columns.map((c) => ({ value: c.header })),
      });
    }

    if (this.searchForm.controls.institutionAudit.value) {
      headers.push({
        title: 'Institution Audit History',
        sort: 3,
        count: results.institutionAuditLogs.length,
        columns: [
          ...columns.map((c) => ({ value: c.header })),
          { value: 'Field Name' },
          { value: 'Entity Type' },
          { value: 'Contact Type' },
          { value: 'Field Old Value' },
          { value: 'Field New Value' },
        ],
      });
    }

    if (this.searchForm.controls.institutionFcoi.value) {
      headers.push({
        title: 'Institution FCOI Designation',
        sort: 4,
        count: results.subInstitutionFCOIPolicies.length,
        columns: [
          { value: 'Institution ID' },
          { value: 'DUNS' },
          { value: 'UEI' },
          { value: 'Institution Name' },
          { value: 'Proposal ID' },
          { value: 'Subaward Number' },
          { value: 'FCOI Designation' },
          { value: 'Risk Category' },
          { value: 'Supplier ID' },
          { value: 'Foreign Institution' },
          { value: 'Institution Status' },
        ],
      });
    }
    return headers.sort((a, b) => a.sort - b.sort);
  }

  private getAllColumns(headers: any[]): any[] {
    const allColumns = [];
    headers.forEach((header) => {
      header.columns.forEach((col) => {
        if (!allColumns.find((c) => c.value === col.value)) {
          allColumns.push(col);
        }
      });
    });
    return allColumns;
  }

  private addHeadersAndStyles(worksheet, headers) {
    if (!headers || !Array.isArray(headers) || headers.length === 0) {
      console.error('Headers array is undefined, null, or empty.');
      return;
    }

    worksheet.columns.forEach((column) => {
      column.width = 20;
    });

    let rowOffset = 10;

    headers.forEach((header, index) => {
      if (
        !header ||
        typeof header !== 'object' ||
        !header.title ||
        !header.columns
      ) {
        console.warn('Skipping invalid header:', header);
        return;
      }

      // Title
      worksheet.spliceRows(rowOffset, 0, [header.title]);
      worksheet.mergeCells(rowOffset, 1, rowOffset, header.columns.length);
      const titleCell = worksheet.getCell(`A${rowOffset}`);
      titleCell.alignment = { horizontal: 'center' };
      titleCell.font = { bold: true, size: 12 };
      rowOffset++;

      // Header
      const gridHeader = header.columns.map((d) => d?.value || '');

      // Sections Offset
      switch (index) {
        case 0:
          worksheet.spliceRows(rowOffset, 1, gridHeader);
          break;
        case 1:
          worksheet.spliceRows(rowOffset, 0, gridHeader);
          break;
        case 2:
          worksheet.spliceRows(rowOffset, 0, gridHeader);
          break;
        default:
          worksheet.spliceRows(rowOffset, 0, gridHeader);
          break;
      }
      rowOffset++;

      // Header Style
      worksheet.getRow(rowOffset - 1).eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFE9F3FC' },
          bgColor: { argb: 'FFFFFFFF' },
        };
        cell.font = {
          color: { argb: '00000000' },
          bold: true,
        };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });

      rowOffset += header.count;
      worksheet.spliceRows(rowOffset, 0, []);
      rowOffset++;
    });
  }

  private generateReportData(
    results: InstitutionAuditReportResponse,
    headers: any[],
  ) {
    const data = [];

    headers.forEach((header) => {
      switch (header.title) {
        case 'New Institutions Created':
          results.newInstitutions.forEach((e) => {
            data.push([
              e.institutionId,
              e.dunsNumber,
              e.uniqueEntityId,
              e.institutionName,
              e.riskCategory,
              e.supplierId,
              e.foreign,
              e.createdBy,
              e.createdDate ? new Date(e.createdDate).toLocaleDateString() : '',
              e.status,
            ]);
          });
          break;
        case 'New Institutions Completed':
          results.completedInstitutions.forEach((e) => {
            data.push([
              e.institutionId,
              e.dunsNumber,
              e.uniqueEntityId,
              e.institutionName,
              e.riskCategory,
              e.supplierId,
              e.foreign,
              e.createdBy,
              e.createdDate ? new Date(e.createdDate).toLocaleDateString() : '',
              e.status,
            ]);
          });
          break;
        case 'Institution Audit History':
          results.institutionAuditLogs.forEach((e) => {
            data.push([
              e.institutionId,
              e.dunsNumber,
              e.uniqueEntityId,
              e.institutionName,
              e.riskCategory,
              e.supplierId,
              e.foreign,
              e.createdBy,
              e.createdDate ? new Date(e.createdDate).toLocaleDateString() : '',
              e.status,
              e.fieldName,
              e.entityType,
              e.contactType,
              e.fieldOldValue,
              e.fieldNewValue,
            ]);
          });
          break;
        case 'Institution FCOI Designation':
          results.subInstitutionFCOIPolicies.forEach((e) => {
            data.push([
              e.institutionId,
              e.dunsNumber,
              e.uniqueEntityId,
              e.institutionName,
              e.proposalId,
              e.agreementNumber,
              e.fcoiPolicy,
              e.riskCategory,
              e.supplierId,
              e.foreign,
              e.status,
            ]);
          });
          break;
      }
    });

    return data;
  }

  clear() {
    this.searchForm.controls.dateFrom.setValue('');
    this.searchForm.controls.dateThru.setValue('');
    this.searchForm.controls.institutionName.setValue('');
    this.searchForm.controls.dunsNumber.setValue('');
    this.searchForm.controls.uniqueEntityId.setValue('');
    this.searchForm.controls.updatedBy.setValue('');
    this.searchForm.controls.institutionsCreated.setValue('');
    this.searchForm.controls.institutionsCompleted.setValue('');
    this.searchForm.controls.institutionAudit.setValue('');
    this.searchForm.controls.institutionFcoi.setValue('');
    this.feedbackService.clearAlert();
  }
}
