import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SubawardsService } from '../subawards.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'subs-fully-executed-agreement',
  templateUrl: './fully-executed-agreement.component.html',
})
export class FullyExecutedAgreementComponent {
  @Input() canEdit: boolean;
  @Output() agreementUploaded: EventEmitter<any> = new EventEmitter<File>();
  @Output() uploadCanceled: EventEmitter<void> = new EventEmitter<void>();
  @Output() resendInternalDistributionEmail: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() resendExternalPONotificationEmail: EventEmitter<void> =
    new EventEmitter<void>();

  feaForm$: Observable<FormGroup | null>;
  showResendEmails$: Observable<boolean>;

  constructor(public subawardsService: SubawardsService) {
    this.feaForm$ = this.subawardsService.FeaForm;

    this.showResendEmails$ = combineLatest([
      this.subawardsService.InternalDistributionSent,
      this.subawardsService.PoNotificationSent,
      this.subawardsService.FeaUploaded,
    ]).pipe(
      map(([internalSent, poSent, feaUploaded]) => {
        return feaUploaded === true && (!internalSent || !poSent);
      }),
    );
  }

  callback = (file: File | null, fileId?: string) => {
    if (!file || !(file instanceof File)) {
      this.subawardsService.setFeaUploaded(false, null);
      this.subawardsService.setFeaFileData(null, null, null, null);
      this.subawardsService.setPoNotificationSent(false);
      this.subawardsService.setInternalDistributionSent(false);
      return;
    }

    const subaward = this.subawardsService['subaward'].getValue();
    const subrecipientId = subaward ? subaward.id : null;

    const feaForm = this.subawardsService['feaForm'].getValue();
    if (!feaForm) {
      return;
    }

    feaForm.patchValue(
      {
        fileId: fileId || '',
        fileName: file.name,
        dateUploaded: new Date().toISOString(),
      },
      { emitEvent: false },
    );

    this.subawardsService.setFeaForm(feaForm);

    this.subawardsService.setFeaFileData(
      fileId || '',
      subrecipientId,
      file.name,
      new Date().toISOString(),
    );

    this.subawardsService.setFeaUploaded(true, {
      file,
      fileId,
      fromUploadEvent: true,
    });
  };

  canceledCallback = () => {
    this.uploadCanceled.emit();
  };

  sendInternalDistributionEmail() {
    this.resendInternalDistributionEmail.emit(true);
  }

  sendExternalPONotificationEmail() {
    this.resendExternalPONotificationEmail.emit();
  }
}
