<div [formGroup]="form">
  <subs-input
    label="* Address 1"
    controlName="addressLine1"
    maxLength="80"
    errorMessage="A value is required."
  ></subs-input>

  <subs-input
    label="Address 2"
    controlName="addressLine2"
    maxLength="80"
  ></subs-input>

  <subs-input
    label="Address 3"
    controlName="addressLine3"
    maxLength="80"
  ></subs-input>

  <subs-input
    label="Address 4"
    controlName="addressLine4"
    maxLength="80"
  ></subs-input>

  <div class="clr-row">
    <subs-input
      class="clr-col-lg-5"
      label="* City"
      controlName="city"
      maxLength="55"
      errorMessage="A value is required."
    ></subs-input>

    <subs-state-province
      class="clr-col-lg-auto"
      [options]="stateOptions"
      [label]="stateRequiredIndicator + 'State/Province'"
      labelLength="6"
      controlName="state"
      errorMessage="{{ stateErrorMessage }}"
    ></subs-state-province>

    <subs-input
      class="clr-col-lg"
      [label]="stateRequiredIndicator + 'Zip code + 4'"
      controlName="zip"
      labelStyle="width:4rem;"
      maxLength="30"
      errorMessage="{{ zipErrorMessage }}"
      helperText="Only required for domestic subrecipient"
    ></subs-input>
  </div>

  <div class="clr-row">
    <subs-country
      class="clr-col"
      [options]="countryOptions"
      controlName="countryCode"
      [label]="countryRequiredIndicator + 'Country'"
      errorMessage="{{ setCountryCodeErrorMessage() }}"
    ></subs-country>

    <subs-input
      *ngIf="includeCongressionalDistrict"
      class="clr-col"
      [label]="stateRequiredIndicator + 'Congressional District'"
      controlName="congressionalDistrict"
      labelStyle="width:4rem;"
      maxLength="6"
      errorMessage="Only alphanumeric and special characters allowed. No 'spaces' allowed."
      helperText="Only required for domestic subrecipient"
    ></subs-input>
  </div>
</div>
