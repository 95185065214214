<form clrForm [formGroup]="searchForm">
  <div class="clr-row">
    <h3>Enter Report Filters:</h3>
  </div>
  <div class="clr-row">
    <div class="clr-col">
      <subs-date controlName="dateFrom" class="clr-col"> </subs-date>
      <subs-date controlName="dateThru" class="clr-col"> </subs-date>
    </div>
  </div>
  <div class="clr-row">
    <subs-input
      class="clr-col"
      controlName="institutionName"
      labelLength="5"
      placeholder="Institution name"
    ></subs-input>
    <subs-input
      class="clr-col"
      controlName="dunsNumber"
      labelLength="5"
      placeholder="DUNS"
    ></subs-input>
  </div>
  <div class="clr-row">
    <subs-input
      class="clr-col"
      controlName="uniqueEntityId"
      labelLength="5"
      placeholder="UEI"
    ></subs-input>
    <subs-input
      class="clr-col"
      controlName="updatedBy"
      labelLength="5"
      placeholder="Updated by user"
    ></subs-input>
  </div>
  <div class="clr-row">
    <div style="margin-top:24px;">
      <h3>Select Report Options:</h3>
    </div>
  </div>
  <div class="clr-row">
    <subs-checkbox
      class="clr-col"
      controlName="institutionsCreated"
      labelLength="8"
      label="New Institutions Created"
    ></subs-checkbox>
  </div>
  <div class="clr-row">
    <subs-checkbox
      class="clr-col"
      controlName="institutionsCompleted"
      labelLength="8"
      label="New Institutions Completed"
    ></subs-checkbox>
  </div>
  <div class="clr-row">
    <subs-checkbox
      class="clr-col"
      controlName="institutionAudit"
      labelLength="8"
      label="Institution Audit History"
    ></subs-checkbox>
  </div>
  <div class="clr-row">
    <subs-checkbox
      class="clr-col"
      controlName="institutionFcoi"
      labelLength="8"
      label="Institution FCOI Designation"
    ></subs-checkbox>
  </div>
  <div class="clr-row">
    <div style="margin-top:2.5%;">
      <subs-alert [alertBehavior]="alertSubject"> </subs-alert>
      <button
        class="btn btn-primary"
        [clrLoading]="submitState | async"
        (click)="search()"
        data-test-id="search-btn"
        [disabled]="!searchForm.valid"
      >
        Submit
      </button>

      <button
        class="btn btn-outline"
        (click)="clear()"
        data-test-id="clear-btn"
      >
        Clear
      </button>
    </div>
  </div>
</form>
