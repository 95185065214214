import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { SubrecipientAuditLog } from '../../../api.service';

@Component({
  selector: 'subs-audit-log-card',
  templateUrl: './audit-log-card.component.html',
  styleUrls: ['./audit-log-card.component.scss'],
  providers: [DatePipe],
})
export class AuditLogCardComponent implements OnInit {
  prefixToRemove = 'Status changed to ';

  constructor(
    private fb: UntypedFormBuilder,
    private datePipe: DatePipe,
  ) {}

  @Input() auditLog: SubrecipientAuditLog;
  form = this.fb.group({
    action: [],
    description: [],
    created: [],
    createdBy: [],
  });

  ngOnInit() {
    this.form.setValue({
      action: this.getAction(),
      description: this.auditLog.description,
      created: this.datePipe.transform(
        this.auditLog.created,
        'MM/dd/yyyy hh:mm aaa',
      ),
      createdBy: this.auditLog.createdBy,
    });
  }

  private getAction() {
    if (this.auditLog.action === 'RD') {
      return 'I. Returned to DA';
    } else if (this.auditLog.action === 'NPO') {
      return this.auditLog.description;
    }
    return this.auditLog.description.startsWith(this.prefixToRemove)
      ? this.auditLog.description.substring(this.prefixToRemove.length)
      : this.auditLog.description;
  }
}
