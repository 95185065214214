import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { AlertOptions, AlertViewOptions } from './alert-options';

@Component({
  selector: 'subs-alert',
  templateUrl: './alert.component.html',
})
export class AlertComponent implements OnInit {
  alertOptions: AlertViewOptions;
  @Input() alertBehavior: Observable<AlertOptions>;

  constructor() {
    this.alertOptions = {
      isClosed: true,
      message: '',
      type: 'danger',
      canBeClosed: true,
    };
  }

  ngOnInit() {
    this.alertBehavior.subscribe((val) => {
      if (!val) {
        this.alertOptions = {
          isClosed: true,
          message: '',
          type: 'danger',
          canBeClosed: true,
        };
      } else {
        this.alertOptions = { ...val, isClosed: false };
      }
    });
  }
}
