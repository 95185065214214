import { Injectable } from '@angular/core';
import * as Excel from 'exceljs';
import * as fs from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { Subaward, GetProject } from '../api.service';

@Injectable()
export class ExcelExportService {
  constructor(private http: HttpClient) {}

  public async generateExcel(
    fileName,
    title,
    data,
    columns,
    filters?,
    callback?: Function,
    arrNum?,
    removeHeader: boolean = false,
  ) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet();

    let gridSize;
    if (fileName === 'Institutions Audit Report' && Array.isArray(arrNum)) {
      const widestSectionColumns = arrNum.reduce((max, header) => {
        if (header && header.columns && Array.isArray(header.columns)) {
          return header.columns.length > max ? header.columns.length : max;
        }
        return max;
      }, 0);
      gridSize = widestSectionColumns;
    } else {
      gridSize = columns.length;
    }

    this.addReportHeader(worksheet, title, gridSize, filters);
    const gridHeader = [];
    const columnWidths = [];
    columns.forEach((d) => {
      gridHeader.push(d.header);
      columnWidths.push(d.width);
    });
    const gridHeaderRow = worksheet.addRow(gridHeader);

    if (!removeHeader) {
      gridHeaderRow.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'FFE9F3FC',
          },
          bgColor: {
            argb: 'FFFFFFFF',
          },
        };
        cell.font = {
          color: {
            argb: '00000000',
          },
          bold: true,
        };
        cell.border = {
          top: {
            style: 'thin',
          },
          left: {
            style: 'thin',
          },
          bottom: {
            style: 'thin',
          },
          right: {
            style: 'thin',
          },
        };
      });
    }
    data.forEach((d) => {
      const row = worksheet.addRow(d);
      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FFFFFFFF',
        },
      };
      row.font = {
        color: {
          argb: '00000000',
        },
        bold: false,
      };

      row.eachCell((cell) => {
        cell.alignment = { vertical: 'top' };
        cell.border = {
          top: {
            style: 'thin',
          },
          left: {
            style: 'thin',
          },
          bottom: {
            style: 'thin',
          },
          right: {
            style: 'thin',
          },
        };
      });
    });

    columnWidths.forEach((e, i) => {
      worksheet.getColumn(i + 1).width = e;
    });

    if (callback) {
      callback(worksheet, arrNum);
    }

    const bufferedData = await workbook.xlsx.writeBuffer();
    const blob = new Blob([bufferedData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    fs.saveAs(blob, fileName + '.xlsx');
  }

  private addReportHeader(worksheet, title, gridSize, filters?) {
    const date = new Date();
    worksheet.addRow([date]);
    worksheet.addRow(['Washington University']);
    worksheet.addRow(['Office of Sponsored Research Services']);
    worksheet.addRow(['SUB System Reports']);
    worksheet.addRow([]);
    const titleRow = worksheet.addRow([title]);
    worksheet.addRow([]);
    let headerHeight = 8;

    if (filters) {
      worksheet.addRow([filters.value]);
      worksheet.addRow([]);

      worksheet.getRow(7).font = {
        size: 12,
        bold: true,
      };

      headerHeight++;
    }

    for (let i = 1; i < headerHeight; i++) {
      // merge by start row, start column, end row, end column
      worksheet.mergeCells(i, 1, i, gridSize);
    }

    for (let i = 1; i < 5; i++) {
      const row = worksheet.getRow(i);
      row.font = {
        size: 11,
        bold: true,
      };
    }
    titleRow.font = {
      size: 14,
      bold: true,
    };

    worksheet.getCell('A1').alignment = { horizontal: 'right' };
    worksheet.getCell('A2').alignment = { horizontal: 'center' };
    worksheet.getCell('A3').alignment = { horizontal: 'center' };
    worksheet.getCell('A4').alignment = { horizontal: 'center' };
    worksheet.getCell('A6').alignment = { horizontal: 'center' };
    if (filters && filters.centered === true) {
      worksheet.getCell('A8').alignment = { horizontal: 'center' };
    }
  }

  public async createCollaboratorSpreadsheet(
    subaward: Subaward,
    project: GetProject,
  ) {
    const workbook = new Excel.Workbook();
    const response = await this.http
      .get('/assets/Collaborator_Information_Template.xlsx', {
        responseType: 'arraybuffer',
      })
      .toPromise();
    await workbook.xlsx.load(response);
    const collabInfoWorkSheet = workbook.getWorksheet(
      'CollaboratorInformation',
    );

    collabInfoWorkSheet.getCell('B2').value =
      subaward.washUInformation.subawardId;

    collabInfoWorkSheet.getCell('D2').value =
      subaward.washUInformation.awardYear;

    collabInfoWorkSheet.getCell('F2').value = subaward.washUInformation.fund;

    collabInfoWorkSheet.getCell('B3').value =
      subaward.washUInformation.proposalId;

    collabInfoWorkSheet.getCell('D3').value =
      subaward.washUInformation.amendment;

    collabInfoWorkSheet.getCell('F3').value =
      subaward.washUInformation.issuingFundSubClassBudgetObject;

    collabInfoWorkSheet.getCell('B4').value = project.awardDepartment;

    collabInfoWorkSheet.getCell('D4').value =
      subaward.washUInformation.issuingDepartment;

    collabInfoWorkSheet.getCell('F4').value =
      project.adminFirstName + ' ' + project.adminLastName;

    collabInfoWorkSheet.getCell('B5').value = project.sponsorName;

    collabInfoWorkSheet.getCell('F5').value = project.adminEmail;

    collabInfoWorkSheet.getCell('B6').value =
      project.investigatorFirstName + ' ' + project.investigatorLastName;

    collabInfoWorkSheet.getCell('F6').value = project.adminPhone;

    collabInfoWorkSheet.getCell('B7').value = project.projectTitle;

    const bufferedData = await workbook.xlsx.writeBuffer();

    const blob = new Blob([bufferedData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    fs.saveAs(blob, 'Collaborator Information.xlsx');
  }
}
